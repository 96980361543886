import { theme, DefaultTheme } from "@chakra-ui/core";
import React from "react";
export interface ITheme {
  colors: {
    backgroundPrimary: string;
    bluePrimary: string;
    blackPrimary: string;
    whitePrimary: string;
    redSecondary: string;
    blueSecondary: string;
    greenSecondary: string;
    blueTertiary: string;
    gray: {
      100: string;
      200: string;
      300: string;
    };
  };
}

type MyTheme = ITheme & DefaultTheme;

export const coreTheme: MyTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    backgroundPrimary: "#F0F0F0",
    bluePrimary: "#9BD9E5",
    blackPrimary: "#0E1037",
    whitePrimary: "#FFFFFF",
    redSecondary: "#ED1C24",
    blueSecondary: "#0084F8",
    greenSecondary: "#47C2B1",
    blueTertiary: "#6CBECE",
    gray: {
      50: "F0F5F9",
      100: "#F0F5F9",
      200: "#C9D6DF",
      300: "#52616B",
      400: "#52616B",
      500: "#52616B",
      600: "#52616B",
      700: "#52616B",
      800: "#52616B",
      900: "#52616B",
    },
  },
  breakpoints: ["400px", "800px", "62em"],
  icons: {
    ...theme.icons,
    mapIcon: {
      path: (
        <svg
          width="22"
          height="25"
          viewBox="0 0 22 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="17.7107" cy="4.34757" r="3.11368" stroke="#52616B" />
          <circle cx="6.81751" cy="13.7536" r="5.64191" stroke="#52616B" />
          <circle cx="16.9267" cy="22.2587" r="1.67778" stroke="#52616B" />
          <path d="M15.546 6.50734L11.1429 10.2208" stroke="#52616B" />
          <path d="M15.818 21.1964L11.1483 17.1161" stroke="#52616B" />
        </svg>
      ),
      viewBox: "0,0,22,22",
    },
    flagIcon: {
      path: (
        <svg
          width="32"
          height="28"
          viewBox="0 0 32 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.1672 4.27762C23.6672 6.86042 25.3344 9.77763 26.6672 12.0276C22.4172 13.6948 19.9172 16.1948 17.5844 18.5276C15.3344 20.7776 13.4172 22.6948 9.83444 23.8604C8.66724 21.8604 6.91725 18.7776 5.33445 16.1104C9.58445 14.4432 12.0844 11.9432 14.4172 9.61042C16.6672 7.36042 18.5828 5.36042 22.1672 4.27762ZM23.8329 0.444824C11.6673 2.69482 12.5001 11.7776 0.332886 14.0276L8.16569 27.6104C20.3329 25.3604 19.5001 16.2776 31.6673 14.0276L23.8329 0.444824Z"
            fill="#ED1C24"
          />
        </svg>
      ),
      viewBox: "0,0,32,28",
    },
    groupIcon: {
      path: (
        <svg
          width="27"
          height="21"
          viewBox="0 0 27 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 2.52759C16 3.90883 14.8812 5.02759 13.5 5.02759C12.1188 5.02759 11 3.90883 11 2.52759C11 1.14635 12.1188 0.0275879 13.5 0.0275879C14.8812 0.0275879 16 1.14635 16 2.52759Z"
            fill="#ED1C24"
          />
          <path
            d="M6.83276 5.02759C6.83276 6.40883 5.714 7.52759 4.33276 7.52759C2.95308 7.52759 1.83276 6.40883 1.83276 5.02759C1.83276 3.64635 2.95308 2.52759 4.33276 2.52759C5.714 2.52759 6.83276 3.64635 6.83276 5.02759Z"
            fill="#ED1C24"
          />
          <path
            d="M25.167 5.02759C25.167 6.40883 24.0467 7.52759 22.667 7.52759C21.2858 7.52759 20.167 6.40883 20.167 5.02759C20.167 3.64635 21.2858 2.52759 22.667 2.52759C24.0467 2.52759 25.167 3.64635 25.167 5.02759Z"
            fill="#ED1C24"
          />
          <path
            d="M22.667 9.19482C21.917 9.19482 21.2498 9.44482 20.5842 9.77762L13.5014 13.9448L6.41865 9.77762C5.74989 9.44481 5.08273 9.19482 4.33273 9.19482C1.99993 9.19482 0.165527 11.0276 0.165527 13.362V20.0292H8.49833V14.862L12.6655 17.362C12.8327 17.4448 12.9155 17.5292 13.0827 17.5292C13.2499 17.5292 13.3327 17.612 13.4999 17.612C13.6671 17.612 13.7499 17.612 13.9171 17.5292C14.0843 17.5292 14.1671 17.4464 14.3343 17.362L18.5015 14.862L18.5 20.0276H26.8328V13.3604C26.8328 11.0276 25 9.19482 22.6672 9.19482H22.667Z"
            fill="#ED1C24"
          />
          <path
            d="M13.5 12.0276L17.5 9.69479C17 8.02759 15.4172 6.77759 13.5 6.77759C11.5828 6.77759 10.0828 8.02759 9.5 9.69479L13.5 12.0276Z"
            fill="#ED1C24"
          />
        </svg>
      ),
      viewBox: "0,0,27,21",
    },
    worldIcon: {
      path: (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 0.904053C6.66724 0.904053 0.667236 6.90405 0.667236 14.2369C0.667236 21.5697 6.66724 27.5697 14 27.5697C21.3328 27.5697 27.3328 21.5697 27.3328 14.2369C27.3328 6.90405 21.3328 0.904053 14 0.904053ZM11.5 23.9041C7.16724 22.8213 4.00004 18.9041 4.00004 14.2369C4.00004 11.3197 5.33284 8.65405 7.33284 6.81965V13.4041C7.33284 13.8213 7.50002 14.2369 7.83284 14.5713L9.83284 16.5713V20.9041C9.83284 21.8213 10.5828 22.5713 11.5 22.5713V23.9041ZM16.5 23.9041V22.5697C17.4172 22.5697 18.1672 21.8197 18.1672 20.9025V18.4025C18.1672 16.9853 17.0844 15.9025 15.6672 15.9025H13.8344L10.6672 12.7369V11.7369H14C14.9172 11.7369 15.6672 10.9869 15.6672 10.0697V6.73685C16.5844 6.73685 17.3344 5.98685 17.3344 5.06965V4.81965C20.1672 5.81965 22.4172 8.06965 23.4172 10.9025H23.1672C22.25 10.9025 21.5 11.6525 21.5 12.5697C21.5 13.4869 22.25 14.2369 23.1672 14.2369C23.5 14.2369 23.75 14.154 24 13.9869V14.2369C24 18.9041 20.8328 22.8197 16.5 23.9041Z"
            fill="#ED1C24"
          />
        </svg>
      ),
      viewBox: "0,0,28,28",
    },
    shieldIcon: {
      path: (
        <svg
          width="24"
          height="28"
          viewBox="0 0 24 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.6672 4.23685C15.25 4.23685 12 0.904053 12 0.904053C12 0.904053 8.74996 4.23685 5.33276 4.23685C2.08276 4.23685 0.332764 1.73685 0.332764 1.73685V17.5697C0.332764 25.1525 6.99996 22.5697 12 27.5697C17 22.5697 23.6672 25.1525 23.6672 17.5697V1.73685C23.6672 1.73685 21.9172 4.23685 18.6672 4.23685ZM17.0828 20.9041C15.5828 21.4041 13.8328 22.0713 12.0828 23.2369L12 23.154V14.2368H3.66719V7.40404C4.16719 7.48685 4.74999 7.57123 5.33439 7.57123C7.91719 7.57123 10.3344 6.32123 12.0016 5.23843L12 14.2368H20.3328V17.5696C20.3328 19.8196 20 19.904 17.0828 20.904L17.0828 20.9041Z"
            fill="#ED1C24"
          />
        </svg>
      ),
      viewBox: "0,0,24,28",
    },
    menuIcon: {
      path: (
        <svg
          width="22"
          height="16"
          viewBox="0 0 22 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="20.7349"
            y1="14.8193"
            x2="4.98486"
            y2="14.8193"
            stroke="#52616B"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
          <line
            x1="20.7349"
            y1="8.02417"
            x2="4.98486"
            y2="8.02417"
            stroke="#52616B"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
          <line
            x1="20.7349"
            y1="1.11523"
            x2="4.98486"
            y2="1.11523"
            stroke="#52616B"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
          <circle cx="1.25249" cy="1.1624" r="0.740279" fill="#52616B" />
          <circle cx="1.25249" cy="8.00004" r="0.740279" fill="#52616B" />
          <circle cx="1.25249" cy="14.8377" r="0.740279" fill="#52616B" />
        </svg>
      ),
      viewBox: "0,0,22,16",
    },
  },
};
