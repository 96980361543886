/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import { coreTheme } from "./src/theme";
import "firebase/firestore";

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={coreTheme}>
      <CSSReset />
      {element}
    </ThemeProvider>
  );
};
